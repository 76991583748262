import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../app.state';
import { getUser, getUserPhoto } from '../../auth/store/auth.selectors';
import { OpenLeftSidenav, OpenRightSidenav } from '../store/layout.actions';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent {
  user$ = this._store.pipe(select(getUser));
  userPhoto$ = this._store.pipe(select(getUserPhoto));

  constructor(private _store: Store<AppState>) {
  }

  openLeftSidenav() {
    this._store.dispatch(new OpenLeftSidenav());
  }

  openRightSidenav() {
    this._store.dispatch(new OpenRightSidenav());
  }
}
