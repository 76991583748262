import { Injectable } from '@angular/core';
import { Observable, of, throwError, empty } from 'rxjs';
import { Book, IBook } from '../models/book.model';
import * as _ from 'node_modules/lodash/index';

@Injectable({ providedIn: 'root' })
export class BookService {
  private _books: Book[] = [{
    id: uuidv4(),
    title: 'Quo Vadis',
    author: 'Henryk Sienkiewicz',
    genre: 'Fikcja historyczna',
    isFavorite: true
  }, {
    id: uuidv4(),
    title: 'Potop',
    author: 'Henryk Sienkiewicz',
    genre: 'Fikcja historyczna',
    isFavorite: false
  }, {
    id: uuidv4(),
    title: 'W pustyni i w puszczy',
    author: 'Henryk Sienkiewicz',
    genre: 'Powieść',
    isFavorite: false
  }]

  public constructor() {
  }

  getAll(): Observable<Book[]> {
    return of(_.cloneDeep(this._books));
  }

  get(id: string): Observable<Book> {
    return of(_.cloneDeep(this._books.find(x => x.id == id)));
  }

  add(newBook: Book): Observable<Book> {
    let book = _.cloneDeep(newBook);
    book.id = uuidv4();
    this._books.push(book);
    return of(book);
  }

  update(book: Book): Observable<Book> {
    let index = this._books.findIndex(x => x.id == book.id);
    if (index == -1) return throwError("Entity not found");

    this._books[index] = book;
    return of(book);
  }

  delete(id: string): Observable<any> {
    let index = this._books.findIndex(x => x.id == id);
    if (index > -1) {
      this._books = this._books.slice(index, 1);
    }

    return empty();
  }
}

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}
