import { NgModule, ModuleWithProviders, Optional, SkipSelf, APP_INITIALIZER, Injector } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MsalInterceptor, MsalModule } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
import { StoreModule } from '@ngrx/store';
import { AuthService } from './auth/auth.service';
import { EffectsModule } from '@ngrx/effects';
import { MaterialModule } from 'src/shared/material.module';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { StoreRouterConnectingModule, RouterStateSerializer } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { msalConfig, angularMsalConfig } from './auth/auth.models';
import { TopbarComponent } from './layout/topbar/topbar.component';
import { UserSidenavComponent } from './layout/user-sidenav/user-sidenav.component';
import { MenuSidenavComponent } from './layout/menu-sidenav/menu-sidenav.component';
import { BusyIndicatorInterceptor } from './interceptors/busy-indicator.interceptor';
import { CustomMsalGuard } from './auth/custom-msal.guard.service';
import { CustomRouterStateSerializer } from './router-store/router.state';
import { metaReducers } from './app.state';
import { AuthStoreModule } from './auth/store/auth-store.module';
import { LayoutStoreModule } from './layout/store/layout-store.module';
import { RouterStoreModule } from './router-store/router-store.module';

export function init(injector: Injector) {
  return () => injector.get(AuthService).startListening();
}

@NgModule({
  declarations: [
    // layout
    TopbarComponent,
    UserSidenavComponent,
    MenuSidenavComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    MaterialModule,
    MsalModule.forRoot(msalConfig, angularMsalConfig),

    AuthStoreModule,
    LayoutStoreModule,
    RouterStoreModule,

    StoreModule.forRoot(<any>{}, { metaReducers }),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production })
  ],
  exports: [
    CommonModule,
    FormsModule,

    // layout
    TopbarComponent,
    UserSidenavComponent,
    MenuSidenavComponent
  ],
  providers: [
    CustomMsalGuard
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule has already been loaded. You should only import Core modules in the AppModule only.');
    }
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        {
          provide: APP_INITIALIZER,
          useFactory: init,
          deps: [Injector],
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: BusyIndicatorInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: MsalInterceptor,
          multi: true
        },
        {
          provide: RouterStateSerializer,
          useClass: CustomRouterStateSerializer
        },
        AuthService,
      ]
    }
  }
}
