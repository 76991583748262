import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { NotificationService } from 'src/core/notifications/notification.service';
import { LoadBooks } from './store/books.actions';
import { NavigateToBookCreateForm } from '../../core/router-store/router.actions';
import { AppState } from '../../core/app.state';

@Component({
  selector: 'app-books',
  templateUrl: './books.component.html',
  styleUrls: ['./books.component.scss']
})
export class BooksComponent implements OnInit {
  constructor(
    private _store: Store<AppState>,
    private _notificationService: NotificationService
  ) { }

  ngOnInit() {
    this._store.dispatch(new LoadBooks());
  }

  createBook() {
    this._store.dispatch(new NavigateToBookCreateForm());
  }
}
