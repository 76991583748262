import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { Book } from 'src/core/models/book.model';
import { Store, select } from '@ngrx/store';
import { LoadBooks, UpdateBook } from '../../store/books.actions';
import { getBookFromUrl } from '../../store/books.selectors';
import { AppState } from '../../../../core/app.state';

@Component({
  selector: 'app-book-update-form-container',
  templateUrl: './book-update-form-container.component.html',
  styleUrls: ['./book-update-form-container.component.scss'],
})
export class BookUpdateFormContainerComponent implements OnInit {
  book$ = this._store.pipe(select(getBookFromUrl));

  constructor(private _store: Store<AppState>) {
  }

  ngOnInit() {
    this._store.dispatch(new LoadBooks());
  }

  onSave(book: Book) {
    this._store.dispatch(new UpdateBook({ book: book }));
  }
}
