import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { Book } from 'src/core/models/book.model';
import * as _ from 'node_modules/lodash/index';

@Component({
  selector: 'app-book-form',
  templateUrl: './book-form.component.html',
  styleUrls: ['./book-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BookFormComponent {
  readonly bookGenres = [
    'Przyrodnicze',
    'Historyczne',
    'Fantasy',
    'Science-Fiction',
    'Horrory',
    'Dokumentalne',
    'Biografie / Autobiografie',
    'Pamiętniki',
    'Dzienniki',
    'Komedie',
    'Tragedie',
    'Paranormal Romance',
    'Kryminały',
    'Detektywistyczne',
    'Poradniki',
    'Hobbistyczne',
    'Naukowe',
    'Psychologiczne',
    'Na faktach',
    'Przygodowe',
    'Młodzieżowe',
    'Fikcja historyczna',
    'Powieść'
  ];

  @Input()
  book: Book = <Book>{};

  @Output()
  save = new EventEmitter<Book>();

  onSave() {
    this.save.emit(this.book);
  }
}
