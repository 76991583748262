import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { Book } from 'src/core/models/book.model';
import { Store } from '@ngrx/store';
import { CreateBook, LoadBooks } from '../../store/books.actions';
import { AppState } from '../../../../core/app.state';

@Component({
  selector: 'app-book-create-form-container',
  templateUrl: './book-create-form-container.component.html',
  styleUrls: ['./book-create-form-container.component.scss'],
})
export class BookCreateFormContainerComponent implements OnInit {
  constructor(private _store: Store<AppState>) {

  }

  ngOnInit() {
    this._store.dispatch(new LoadBooks());
  }

  onSave(book: Book) {
    this._store.dispatch(new CreateBook({ book: book }));
  }
}
