
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { layoutReducer } from './layout.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('layout', layoutReducer),
  ]
})
export class LayoutStoreModule { }
