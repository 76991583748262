import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { tap, catchError, finalize } from 'rxjs/operators';
import { BusyIndicatorService } from './busy-indicator.service';

@Injectable()
export class BusyIndicatorInterceptor implements HttpInterceptor {
  constructor(private _busyIndicatorService: BusyIndicatorService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    this._busyIndicatorService.newOperation();
    return next.handle(request).pipe(
      catchError(err => {
        throw err;
      }),
      finalize(() => {
        this._busyIndicatorService.operationFinished();
      })
    );
  }
}
