import { LayoutState, initialLayoutState } from './layout.state';
import { LayoutActions, LayoutActionTypes } from './layout.actions';

export const layoutReducer = (state = initialLayoutState, action: LayoutActions): LayoutState => {
  switch (action.type) {
    case LayoutActionTypes.OpenLeftSidenav: {
      return {
        ...state,
        showLeftSidenav: true
      }
    }

    case LayoutActionTypes.CloseLeftSidenav: {
      return {
        ...state,
        showLeftSidenav: false
      }
    }

    case LayoutActionTypes.OpenRightSidenav: {
      return {
        ...state,
        showRightSidenav: true
      }
    }

    case LayoutActionTypes.CloseRightSidenav: {
      return {
        ...state,
        showRightSidenav: false
      }
    }

    default:
      return state;
  }
}
