<table mat-table [dataSource]="dataSource" matSort aria-label="Books" class="full-width-table">
  <!-- Title Column -->
  <ng-container matColumnDef="title">
    <th mat-header-cell *matHeaderCellDef>Tytuł</th>
    <td mat-cell *matCellDef="let row">{{row.title}}</td>
  </ng-container>

  <!-- Author Column -->
  <ng-container matColumnDef="author">
    <th mat-header-cell *matHeaderCellDef>Autor</th>
    <td mat-cell *matCellDef="let row">{{row.author}}</td>
  </ng-container>

  <!-- Genre Column -->
  <ng-container matColumnDef="genre">
    <th mat-header-cell *matHeaderCellDef>Kategoria</th>
    <td mat-cell *matCellDef="let row">{{row.genre}}</td>
  </ng-container>

  <!-- IsFavorite Column -->
  <ng-container matColumnDef="isFavorite">
    <th mat-header-cell *matHeaderCellDef>
      Ulubiona
    </th>
    <td mat-cell *matCellDef="let row">
      <span *ngIf="row.isFavorite">
        <mat-icon>start</mat-icon>
      </span>
    </td>
  </ng-container>

  <!-- Edit Button -->
  <ng-container matColumnDef="editAction">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let row">
      <mat-icon (click)="onEditButtonClick(row)" class="pointer-cursor">edit</mat-icon>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
