<mat-toolbar class="bg-gray">
  Moje konto
  <button mat-icon-button color="primary" (click)="closeUserSidenav()" class="margin-left-80">
    <mat-icon>close</mat-icon>
  </button>
</mat-toolbar>
<div class="display-flex">
  <ng-container *ngIf="user$ | async as user">
    <div class="photo-container">
      <img [src]="userPhoto$ | async" />
    </div>
    <h3 class="user-title">{{ user.displayName }}</h3>
    <h5 class="user-details">{{ user.mail }}</h5>
    <mat-nav-list>
      <a mat-list-item>
        <mat-icon class="margin-right-10">folder</mat-icon> Moje zadania
      </a>
      <a mat-list-item (click)="logout()">
        <mat-icon class="margin-right-10">exit_to_app</mat-icon> Wyloguj
      </a>
    </mat-nav-list>
  </ng-container>
</div>
