import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../../core/app.state';
import { getBooksGenresSummary } from '../store/books.selectors';

@Component({
  selector: 'app-books-genres-summary',
  templateUrl: './books-genres-summary.component.html',
  styleUrls: ['./books-genres-summary.component.scss']
})
export class BooksGenresSummaryComponent  {
  booksSummary$ = this._store.pipe(select(getBooksGenresSummary));

  constructor(
    private _store: Store<AppState>,
  ) { }
}
