import { Action } from '@ngrx/store';
import { AzureUser } from 'src/core/models/azure-user.model';
import { SafeUrl } from '@angular/platform-browser';

export enum AuthActionTypes {
  Login = '[Auth] Login',
  LoginSuccess = '[Auth] Login Success',
  Logout = '[Auth] Logout',

  LoadUserData = '[Auth] Load User Data',
  LoadUserDataSuccess = '[Auth] Load User Data Success',

  LoadUserPhoto = '[Auth] Load User Photo',
  LoadUserPhotoSuccess = '[Auth] Load User Photo Success',
  LoadUserPhotoFailure = '[Auth] Load User Photo Failure',
}

export class Login implements Action {
  public readonly type = AuthActionTypes.Login;
}

export class LoginSuccess implements Action {
  public readonly type = AuthActionTypes.LoginSuccess;
  constructor(public payload: { authToken: string }) { }
}

export class Logout implements Action {
  public readonly type = AuthActionTypes.Logout;
}

export class LoadUserData implements Action {
  public readonly type = AuthActionTypes.LoadUserData;
}

export class LoadUserDataSuccess implements Action {
  public readonly type = AuthActionTypes.LoadUserDataSuccess;
  public constructor(public payload: { user: AzureUser }) { }
}

export class LoadUserPhoto implements Action {
  public readonly type = AuthActionTypes.LoadUserPhoto;
}

export class LoadUserPhotoSuccess implements Action {
  public readonly type = AuthActionTypes.LoadUserPhotoSuccess;
  constructor(public payload: { photoUrl: SafeUrl }) { }
}

export class LoadUserPhotoFailure implements Action {
  public readonly type = AuthActionTypes.LoadUserPhotoFailure;
  constructor(public payload: { defaultPhotoUrl: SafeUrl }) { }
}

export type AuthActions
  = Login
  | LoginSuccess
  | Logout
  | LoadUserData
  | LoadUserDataSuccess
  | LoadUserPhoto
  | LoadUserPhotoSuccess
  | LoadUserPhotoFailure;
