import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Book } from 'src/core/models/book.model';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-books-list',
  templateUrl: './books-list.component.html',
  styleUrls: ['./books-list.component.scss']
})
export class BooksListComponent implements OnInit {
  @Input()
  set books(books: Book[]) {
    this.dataSource.data = books;
  }

  @Output()
  edit = new EventEmitter<Book>();

  displayedColumns = ['title', 'author', 'genre', 'isFavorite', 'editAction'];
  dataSource: MatTableDataSource<Book> = new MatTableDataSource<Book>();

  constructor(
  ) { }


  ngOnInit() {
  }

  onEditButtonClick(book: Book) {
    this.edit.emit(book);
  }
}
