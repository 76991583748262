<mat-toolbar>
  <button mat-icon-button color="primary" (click)="closeMenuSidenav()" class="margin-right">
    <mat-icon>apps</mat-icon>
  </button>
  Menu
</mat-toolbar>

<mat-nav-list>
  <a mat-list-item>Menu 1</a>
  <a mat-list-item>Menu 2</a>
  <a mat-list-item>Menu 3</a>
</mat-nav-list>
