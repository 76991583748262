<mat-card class="book-form">
  <mat-form-field>
    <input matInput [(ngModel)]="book.title" placeholder="Tytuł" required>
  </mat-form-field>

  <mat-form-field>
    <input matInput [(ngModel)]="book.author" placeholder="Autor" required>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Gatunek</mat-label>
    <mat-select [(value)]="book.genre" required>
      <mat-option *ngFor="let genre of bookGenres" [value]="genre">
        {{genre}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-checkbox [(ngModel)]="book.isFavorite">Ulubione?</mat-checkbox>

  <button mat-button (click)="onSave()">Zapisz</button>
</mat-card>
