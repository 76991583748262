import { Injectable } from '@angular/core';
import { Effect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap, catchError, tap } from 'rxjs/operators';
import { NavigateToBooksList, RouterActionTypes, NavigateToBookCreateForm, NavigateToBookUpdateForm } from './router.actions';
import { Router } from '@angular/router';

@Injectable()
export class RouterEffects {

  @Effect({ dispatch: false })
  navigateToBooksList$ = this._actions$.pipe(
    ofType<NavigateToBooksList>(RouterActionTypes.NavigateToBooksList),
    tap(() => this._router.navigate(['/books']))
  );

  @Effect({ dispatch: false })
  navigateToBookCreateForm$ = this._actions$.pipe(
    ofType<NavigateToBookCreateForm>(RouterActionTypes.NavigateToBookCreateForm),
    tap(() => this._router.navigate(['/book/create']))
  );

  @Effect({ dispatch: false })
  navigateToBookUpdateForm$ = this._actions$.pipe(
    ofType<NavigateToBookUpdateForm>(RouterActionTypes.NavigateToBookUpdateForm),
    map(x => x.payload.book),
    tap(book => this._router.navigate(['/book/edit/' + book.id]))
  );


  constructor(
    private _actions$: Actions,
    private _router: Router
  ) { }
}
