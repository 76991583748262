import { Action } from '@ngrx/store';

export enum LayoutActionTypes {
  OpenLeftSidenav = '[Layout] Open Left Sidenav',
  CloseLeftSidenav = '[Layout] Close Left Sidenav',
  OpenRightSidenav = '[Layout] Open Right Sidenav',
  CloseRightSidenav = '[Layout] Close Right Sidenav',
}

export class OpenLeftSidenav implements Action {
  public readonly type = LayoutActionTypes.OpenLeftSidenav;
}

export class CloseLeftSidenav implements Action {
  public readonly type = LayoutActionTypes.CloseLeftSidenav;
}

export class OpenRightSidenav implements Action {
  public readonly type = LayoutActionTypes.OpenRightSidenav;
}

export class CloseRightSidenav implements Action {
  public readonly type = LayoutActionTypes.CloseRightSidenav;
}

export type LayoutActions
  = OpenLeftSidenav
  | CloseLeftSidenav
  | OpenRightSidenav
  | CloseRightSidenav;
