import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../app.state';
import { CloseLeftSidenav } from '../store/layout.actions';

@Component({
  selector: 'app-menu-sidenav',
  templateUrl: './menu-sidenav.component.html',
  styleUrls: ['./menu-sidenav.component.scss'],
})
export class MenuSidenavComponent {
  constructor(private _store: Store<AppState>) {
  }

  closeMenuSidenav() {
    this._store.dispatch(new CloseLeftSidenav());
  }
}
