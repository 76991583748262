import { AzureUser } from 'src/core/models/azure-user.model';
import { SafeUrl } from '@angular/platform-browser';

export interface AuthState {
  user: AzureUser;
  userPhotoUrl: SafeUrl;
}

export const initialAuthState: AuthState = {
  user: null,
  userPhotoUrl: null
}
