import { NgModule, ModuleWithProviders } from '@angular/core';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { MaterialModule } from './material.module';
import { CommonModule } from '@angular/common';
import { BusyIndicatorComponent } from './components/busy-indicator/busy-indicator.component';
import { DeepCopyPipe } from './pipes/deepCopy.pipe';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
  ],
  declarations: [
    ConfirmDialogComponent,
    BusyIndicatorComponent,
    DeepCopyPipe,
  ],
  exports: [
    ConfirmDialogComponent,
    BusyIndicatorComponent,
    DeepCopyPipe
  ],
  entryComponents: [
    ConfirmDialogComponent
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: []
    }
  }
}
