import { BookActionTypes, BookActions } from './books.actions';
import { initialBooksState, BooksState } from './books.state';

export const booksReducer = (state = initialBooksState, action: BookActions): BooksState => {
  switch (action.type) {
    case BookActionTypes.LoadBooksSuccess: {
      return {
        ...state,
        books: action.payload.books
      }
    }

    case BookActionTypes.CreateBookSuccess: {
      let books = [...state.books];
      books.push(action.payload.book);

      return {
        ...state,
        books: books
      }
    }

    case BookActionTypes.UpdateBookSuccess: {
      let books = [...state.books];
      let index = books.findIndex(x => x.id == action.payload.book.id);
      if (index > -1) books[index] = action.payload.book;

      return {
        ...state,
        books: books
      }
    }

    default:
      return state;
  }
}
