import { Component, OnInit, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { NotificationService } from 'src/core/notifications/notification.service';
import { Book } from 'src/core/models/book.model';
import { getBooks } from '../store/books.selectors';
import { NavigateToBookUpdateForm } from '../../../core/router-store/router.actions';
import { AppState } from '../../../core/app.state';

@Component({
  selector: 'app-books-list-container',
  templateUrl: './books-list-container.component.html',
  styleUrls: ['./books-list-container.component.scss']
})
export class BooksListContainerComponent implements OnInit {
  books$ = this._store.pipe(select(getBooks));

  constructor(
    private _store: Store<AppState>
  ) { }

  ngOnInit() {
  }

  editBook(book: Book) {
    this._store.dispatch(new NavigateToBookUpdateForm({ book: book }));
  }
}
