import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';

@Injectable()
export class AuthRedirectGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _msalService: MsalService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const redirectTo = this._msalService.getRedirectUri();
    this._router.navigateByUrl(redirectTo);
    return false;
  }
}
