import { Injectable } from '@angular/core';
import { Effect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap, catchError, tap } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { LoadUserData, AuthActionTypes, LoadUserPhoto, LoadUserDataSuccess, LoadUserPhotoSuccess, LoadUserPhotoFailure, LoginSuccess, Logout } from './auth.actions';
import { AzureUserService } from 'src/core/services/azure-user.service';
import { AuthService } from 'src/core/auth/auth.service';

@Injectable()
export class AuthEffects {
  @Effect()
  loginSuccess$ = this._actions$.pipe(
    ofType<LoginSuccess>(AuthActionTypes.LoginSuccess),
    switchMap(() => [
      new LoadUserData(),
      new LoadUserPhoto(),
    ])
  );

  @Effect()
  loadUserData$ = this._actions$.pipe(
    ofType<LoadUserData>(AuthActionTypes.LoadUserData),
    switchMap(x => this._azureUserService.getCurrentUser()),
    map(user => new LoadUserDataSuccess({ user: user }))
  );

  @Effect()
  loadUserPhoto$ = this._actions$.pipe(
    ofType<LoadUserPhoto>(AuthActionTypes.LoadUserPhoto),
    switchMap(x => this._azureUserService.getCurrentUserPhoto()),
    map(photo => new LoadUserPhotoSuccess({ photoUrl: this._domSanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(photo)) })),
    catchError(() => of(new LoadUserPhotoFailure({ defaultPhotoUrl: this._domSanitizer.bypassSecurityTrustUrl('./assets/default-user.png') })))
  );

  @Effect({ dispatch: false })
  logout$ = this._actions$.pipe(
    ofType<Logout>(AuthActionTypes.Logout),
    tap(() => {
      this._authService.logout()
    })
  );

  constructor(
    private _actions$: Actions,
    private _domSanitizer: DomSanitizer,
    private _azureUserService: AzureUserService,
    private _authService: AuthService
  ) { }
}
