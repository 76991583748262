<mat-sidenav-container class="sidenav-container">
  <mat-sidenav class="sidenav" fixedInViewport="true" role="dialog" mode="over" [opened]="showLeftSidenav$ | async" (closedStart)="closeLeftSidenav()">
    <app-menu-sidenav></app-menu-sidenav>
  </mat-sidenav>

  <mat-sidenav class="sidenav" position="end" fixedInViewport="true" role="dialog" mode="over" [opened]="showRightSidenav$ | async" (closedStart)="closeRightSidenav()">
    <app-user-sidenav></app-user-sidenav>
  </mat-sidenav>

  <mat-sidenav-content>
    <app-busy-indicator></app-busy-indicator>
    <app-topbar></app-topbar>

    <router-outlet *ngIf="!isIframe"></router-outlet>

  </mat-sidenav-content>
</mat-sidenav-container>
