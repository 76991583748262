import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../app.state';
import { Logout } from '../../auth/store/auth.actions';
import { getUser, getUserPhoto } from '../../auth/store/auth.selectors';
import { CloseRightSidenav } from '../store/layout.actions';

@Component({
  selector: 'app-user-sidenav',
  templateUrl: './user-sidenav.component.html',
  styleUrls: ['./user-sidenav.component.scss'],
})
export class UserSidenavComponent {
  user$ = this._store.pipe(select(getUser));
  userPhoto$ = this._store.pipe(select(getUserPhoto));

  constructor(private _store: Store<AppState>) {
  }

  closeUserSidenav() {
    this._store.dispatch(new CloseRightSidenav());
  }

  logout() {
    this._store.dispatch(new Logout());
  }
}
