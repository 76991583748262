import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { CoreModule } from 'src/core/core.module';
import { SharedModule } from 'src/shared/shared.module';
import { MaterialModule } from 'src/shared/material.module';
import { BooksComponent } from './books.component';
import { BooksListContainerComponent } from './books-list-container/books-list-container.component';
import { BooksListComponent } from './books-list-container/books-list/books-list.component';
import { BookCreateFormContainerComponent } from './forms/book-create-form-container/book-create-form-container.component';
import { BookUpdateFormContainerComponent } from './forms/book-update-form-container/book-update-form-container.component';
import { BookFormComponent } from './forms/book-form/book-form.component';
import { BooksGenresSummaryComponent } from './books-genres-summary/books-genres-summary.component';
import { BooksStoreModule } from './store/books-store.module';

@NgModule({
  declarations: [
    BooksComponent,
    BooksListContainerComponent,
    BooksListComponent,
    BookCreateFormContainerComponent,
    BookUpdateFormContainerComponent,
    BookFormComponent,
    BooksGenresSummaryComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule.forRoot(),
    SharedModule.forRoot(),
    MaterialModule,

    BooksStoreModule
  ],
  providers: [],
})
export class BooksModule { }
