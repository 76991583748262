import { initialAuthState, AuthState } from './auth.state';
import { AuthActions, AuthActionTypes } from './auth.actions';

export const authReducer = (state = initialAuthState, action: AuthActions): AuthState => {
  switch (action.type) {
    case AuthActionTypes.Logout: {
      return {
        ...state,
        user: null,
        userPhotoUrl: null
      }
    }

    case AuthActionTypes.LoadUserDataSuccess: {
      return {
        ...state,
        user: action.payload.user
      }
    }

    case AuthActionTypes.LoadUserPhotoSuccess: {
      return {
        ...state,
        userPhotoUrl: action.payload.photoUrl
      }
    }

    case AuthActionTypes.LoadUserPhotoFailure: {
      return {
        ...state,
        userPhotoUrl: action.payload.defaultPhotoUrl
      }
    }

    default:
      return state;
  }
}
