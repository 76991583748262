import { Configuration } from "msal";
import { environment } from 'src/environments/environment';
import { MsalAngularConfiguration } from '@azure/msal-angular';

export const protectedResourceMap: [string, string[]][] = [
  ['https://graph.microsoft.com/v1.0', ['user.read']],
  [environment.apiUrl, [environment.msalModule.appScope]],
];

export const msalConfig: Configuration = {
  auth: {
    clientId: environment.msalModule.clientID,
    authority: environment.msalModule.authority,
    redirectUri: environment.msalModule.redirectUri,
    navigateToLoginRequestUrl: true
  },
  cache: {
    cacheLocation: 'localStorage',
  },
  system: {
    loadFrameTimeout: 60000,
  }
};

export const angularMsalConfig: MsalAngularConfiguration = {
  popUp: false,
  consentScopes: ['user.read', environment.msalModule.appScope],
  protectedResourceMap: protectedResourceMap
}
