import { createSelector, createFeatureSelector } from '@ngrx/store';
import { BooksState } from './books.state';
import { Book } from 'src/core/models/book.model';
import { getBookIdFromUrl } from '../../../core/router-store/router.selectors';

export const booksSelectors = createFeatureSelector<BooksState>('books');

export const getBooks = createSelector(booksSelectors, (state: BooksState) => state.books);
export const getBookFromUrl = createSelector(booksSelectors, getBookIdFromUrl, (state: BooksState, bookId: string) => {
  let book = state.books.find(x => x.id == bookId);
  return book ? book : {};
});

export const getBooksGenresSummary = createSelector(getBooks, (books: Book[]) => {
  let count = {};
  for (var book of books) {
    if (!count[book.genre]) count[book.genre] = 0;
    count[book.genre]++;
  }

  let result: { genre: string, count: number }[] = [];
  Object.keys(count).forEach(genre => result.push({ genre: genre, count: count[genre] }));

  return result;
});
