import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthRedirectGuard } from 'src/core/auth/auth-redirect-guard.service';
import { MsalGuard } from '@azure/msal-angular';
import { BookCreateFormContainerComponent } from './books/forms/book-create-form-container/book-create-form-container.component';
import { BookUpdateFormContainerComponent } from './books/forms/book-update-form-container/book-update-form-container.component';
import { BooksComponent } from './books/books.component';
import { CustomMsalGuard } from 'src/core/auth/custom-msal.guard.service';

const routes: Routes = [
  { path: 'books', component: BooksComponent/*, canActivate: [CustomMsalGuard]*/ },
  { path: 'book/create', component: BookCreateFormContainerComponent/*, canActivate: [CustomMsalGuard]*/ },
  { path: 'book/edit/:bookId', component: BookUpdateFormContainerComponent/*, canActivate: [CustomMsalGuard]*/ },

  { path: 'auth', redirectTo: '', canActivate: [AuthRedirectGuard] },
  { path: '**', component: BooksComponent/*, canActivate: [CustomMsalGuard]*/ }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
