import { Component } from '@angular/core';
import { BusyIndicatorService } from 'src/core/interceptors/busy-indicator.service';

@Component({
  selector: 'app-busy-indicator',
  templateUrl: './busy-indicator.component.html',
  styleUrls: ['./busy-indicator.component.scss'],
})
export class BusyIndicatorComponent {
  showIndicator = false;

  constructor(private _busyIndicatorService: BusyIndicatorService) {
    this._busyIndicatorService.operationsInProgress$.subscribe(operations => {
      setTimeout(() => this.showIndicator = operations > 0);
    })
  }
}
