import { Injectable } from '@angular/core';
import { Effect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap, catchError, tap } from 'rxjs/operators';
import { BookActionTypes, LoadBooksSuccess, LoadBooks, CreateBook, CreateBookFailure, UpdateBook, UpdateBookFailure } from './books.actions';
import { BookService } from 'src/core/services/book.service';
import { NavigateToBooksList } from '../../../core/router-store/router.actions';

@Injectable()
export class BooksEffects {
  @Effect()
  loadBooks$ = this._actions$.pipe(
    ofType<LoadBooks>(BookActionTypes.LoadBooks),
    switchMap(() => this._bookService.getAll()),
    map(books => new LoadBooksSuccess({ books: books }))
  );

  @Effect()
  createBook$ = this._actions$.pipe(
    ofType<CreateBook>(BookActionTypes.CreateBook),
    map(x => x.payload.book),
    switchMap(book => this._bookService.add(book).pipe(
      map(() => new NavigateToBooksList()),
      catchError(() => of(new CreateBookFailure({ book: book })))
    ))
  );

  @Effect()
  updateBook$ = this._actions$.pipe(
    ofType<UpdateBook>(BookActionTypes.UpdateBook),
    map(x => x.payload.book),
    switchMap(book => this._bookService.update(book).pipe(
      map(() => new NavigateToBooksList()),
      catchError(() => of(new UpdateBookFailure({ book: book }))
      )
    ))
  );

  constructor(
    private _actions$: Actions,
    private _bookService: BookService
  ) { }
}
