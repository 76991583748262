import { Action } from '@ngrx/store';
import { Book } from 'src/core/models/book.model';

export enum BookActionTypes {
  LoadBooks = '[Book] Load Books',
  LoadBooksSuccess = '[Book] Load Books Success',

  CreateBook = '[Book] Create Book',
  CreateBookSuccess = '[Book] Create Book Success',
  CreateBookFailure = '[Book] Create Book Failure',

  UpdateBook = '[Book] Update Book',
  UpdateBookSuccess = '[Book] Update Book Success',
  UpdateBookFailure = '[Book] Update Book Failure'
}

export class LoadBooks implements Action {
  public readonly type = BookActionTypes.LoadBooks;
}

export class LoadBooksSuccess implements Action {
  public readonly type = BookActionTypes.LoadBooksSuccess;
  constructor(public payload: { books: Book[] }) { }
}

export class CreateBook implements Action {
  public readonly type = BookActionTypes.CreateBook;
  constructor(public payload: { book: Book }) { }
}

export class CreateBookSuccess implements Action {
  public readonly type = BookActionTypes.CreateBookSuccess;
  constructor(public payload: { book: Book }) { }
}

export class CreateBookFailure implements Action {
  public readonly type = BookActionTypes.CreateBookFailure;
  constructor(public payload: { book: Book }) { }
}

export class UpdateBook implements Action {
  public readonly type = BookActionTypes.UpdateBook;
  constructor(public payload: { book: Book }) { }
}

export class UpdateBookSuccess implements Action {
  public readonly type = BookActionTypes.UpdateBookSuccess;
  constructor(public payload: { book: Book }) { }
}

export class UpdateBookFailure implements Action {
  public readonly type = BookActionTypes.UpdateBookFailure;
  constructor(public payload: { book: Book }) { }
}

export type BookActions
  = LoadBooks
  | LoadBooksSuccess
  | CreateBook
  | CreateBookSuccess
  | CreateBookFailure
  | UpdateBook
  | UpdateBookSuccess
  | UpdateBookFailure;
