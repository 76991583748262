import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { AzureUser } from '../models/azure-user.model';

@Injectable({ providedIn: 'root' })
export class AzureUserService {
  public constructor(private _httpClient: HttpClient) {
  }

  public getCurrentUser(): Observable<AzureUser> {
    return this._httpClient.get('https://graph.microsoft.com/v1.0/me').pipe(map(response => response as AzureUser));
  }

  public getCurrentUserPhoto(): Observable<Blob> {
    return this._httpClient.get('https://graph.microsoft.com/v1.0/me/photo/$value', { responseType: 'blob' });
  }
}
