<mat-toolbar class="primary-background">
  <mat-toolbar-row>
    <button class="menu-button primary-background" (click)="openLeftSidenav()">
      <mat-icon class="menu-icon">apps</mat-icon>
    </button>
    <span class="spacer"></span>
    <mat-divider class="style-fix" [vertical]="true"></mat-divider>
    <mat-card class="user-photo-container mat-elevation-z">
      <img mat-card-avatar [src]="userPhoto$ | async" class="pointer-cursor" (click)="openRightSidenav()" />
    </mat-card>
  </mat-toolbar-row>
</mat-toolbar>
