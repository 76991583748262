import { Injectable } from "@angular/core";
import { MsalService, BroadcastService } from "@azure/msal-angular";
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { LoginSuccess } from "./store/auth.actions";
import { AuthState } from "./store/auth.state";

@Injectable()
export class AuthService {
  private _subscription: Subscription = new Subscription();

  constructor(
    private _broadcastService: BroadcastService,
    private _msalService: MsalService,
    private _store: Store<AuthState>
  ) {

  }

  get authToken(): string {
    return localStorage['msal.idtoken'];
  }

  public startListening() {
    this._subscription.add(this._broadcastService.subscribe("msal:loginSuccess", (payload) => {
      if (!this.isUserAuthenticated()) {
        this._store.dispatch(new LoginSuccess({ authToken: payload._token }));
      }
    }));

    this._subscription.add(this._broadcastService.subscribe("msal:acquireTokenFailure", (payload) => {
      if (!this.isUserAuthenticated()) {
        this._msalService.loginRedirect();
      }
    }));

    if (this.isUserAuthenticated()) {
      this._store.dispatch(new LoginSuccess({ authToken: this.authToken }));
    }
  }

  public logout() {
    this._msalService.logout();
  }

  public isUserAuthenticated() {
    return !!this._msalService.getAccount();
  }

  public goToLoginSite(): void {
    this._msalService.loginRedirect();
  }

  ngOnDestroy() {
    this._broadcastService.getMSALSubject().next(1);
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }
}
