import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from '../core/app.state';
import { CloseLeftSidenav, CloseRightSidenav } from '../core/layout/store/layout.actions';
import { getShowLeftSidenav, getShowRightSidenav } from '../core/layout/store/layout.selectors';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  showLeftSidenav$ = this._store.pipe(select(getShowLeftSidenav));
  showRightSidenav$ = this._store.pipe(select(getShowRightSidenav));
  isIframe = false;

  constructor(private _store: Store<AppState>) { }

  ngOnInit() {
    this.isIframe = window !== window.parent && !window.opener;
  }

  closeLeftSidenav() {
    this._store.dispatch(new CloseLeftSidenav());
  }

  closeRightSidenav() {
    this._store.dispatch(new CloseRightSidenav());
  }
}
