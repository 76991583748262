import { RouterReducerState } from '@ngrx/router-store';
import { MetaReducer } from '@ngrx/store';
import { storeFreeze } from 'ngrx-store-freeze';
import { BooksState, initialBooksState } from '../app/books/store/books.state';
import { environment } from '../environments/environment';
import { AuthState, initialAuthState } from './auth/store/auth.state';
import { initialLayoutState, LayoutState } from './layout/store/layout.state';
import { RouterStateUrl } from './router-store/router.state';

export interface AppState {
  auth: AuthState;
  books: BooksState;
  layout: LayoutState;
  router?: RouterReducerState<RouterStateUrl>;
}

export const initialAppState: AppState = {
  auth: initialAuthState,
  books: initialBooksState,
  layout: initialLayoutState,
}

export const metaReducers: MetaReducer<AppState>[] = !environment.production
  ? [storeFreeze]
  : [];
