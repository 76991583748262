import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { Observable, of, from } from 'rxjs';
import { flatMap } from 'rxjs/operators';

@Injectable()
export class CustomMsalGuard implements CanActivate {
  constructor(
    private _msalGuard: MsalGuard,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const canActivateResult = this._msalGuard.canActivate(route, state);
    if (typeof canActivateResult === 'boolean') {
      return of(canActivateResult);
    } else {
      return from(canActivateResult).pipe(flatMap((value) => {
        return of(value)
      }));
    }
  }
}
