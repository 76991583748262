export const environment = {
  production: false,
  apiUrl: 'xxx',

  msalModule: {
    clientID: 'ec54751e-d322-493e-8da4-c55c565d8843',
    authority: 'https://login.microsoftonline.com/arpideas.pl/',
    redirectUri: 'https://localhost:4200',
    appScope: 'https://arpideas.pl/85a43a0f-3a4d-4a13-a619-21916e0f68ce/user_impersonation'  // Scope udostnione przez Azure podczas rejestracji aplikacji
  }
};
