import { Action } from '@ngrx/store';
import { Book } from 'src/core/models/book.model';

export enum RouterActionTypes {
  NavigateToBooksList = '[Router] Navigate To Books List',
  NavigateToBookCreateForm = '[Router] Navigate To Book Create Form',
  NavigateToBookUpdateForm = '[Router] Navigate To Book Update Form'
}

export class NavigateToBooksList implements Action {
  public readonly type = RouterActionTypes.NavigateToBooksList;
}

export class NavigateToBookCreateForm implements Action {
  public readonly type = RouterActionTypes.NavigateToBookCreateForm;
}

export class NavigateToBookUpdateForm implements Action {
  public readonly type = RouterActionTypes.NavigateToBookUpdateForm;
  constructor(public payload: { book: Book }) { }
}

export type RouterActions
  = NavigateToBooksList
  | NavigateToBookCreateForm
  | NavigateToBookUpdateForm;
